<template>
  <div class="px-4 py-4" :class="width">
    <hr />
  </div>
</template>

<script>
export default {
  name: 'DividerBlock',
  props: {
    blockData: {
      type: Object,
      required: true
    }
  },
  computed: {
    width() {
      if (this.blockData.width === 'standard') {
        return 'mx-auto rc-container max-w-screen-xl';
      }
      if (this.blockData.width === 'wide') {
        return 'mx-auto rc-container max-w-screen-3xl';
      }
      return null;
    }
  }
};
</script>
