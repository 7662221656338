<template>
  <div class="px-4 py-4" :class="width">
    <ul class="grid grid-cols-1 gap-5 md:grid-cols-2">
      <li v-for="item in blockData.grid" :key="item.id">
        <TextImage :block-data="item" :dark-mode="darkMode" />
      </li>
    </ul>
  </div>
</template>

<script>
import TextImage from 'theme/components/theme/bikebuilder/external/craftcms/TextImage';

export default {
  name: 'ImageTextGrid',
  props: {
    blockData: {
      type: Object,
      required: true
    },
    darkMode: {
      type: String,
      default: 'off'
    }
  },
  components: { TextImage },
  computed: {
    width() {
      if (this.blockData.width === 'standard') {
        return 'mx-auto rc-container max-w-screen-xl';
      }
      if (this.blockData.width === 'wide') {
        return 'mx-auto rc-container max-w-screen-3xl';
      }
      return null;
    }
  }
};
</script>
