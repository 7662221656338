<template>
  <ul>
    <li v-for="(link, index) in linkList" :key="`${link.linkUrl}-${index}`" class="mb-1">
      <router-link
        :to="localizedRoute(link.linkUrl ? link.linkUrl : '')"
        class="text-blue-500 inline-flex items-center space-x-2 hover:text-blue-700 focus:text-blue-700"
      >
        <span>{{ link.linkText }}</span>
        <ArrowRight class="h-4 w-4" />
      </router-link>
    </li>
  </ul>
</template>

<script>
import { localizedRoute } from '@vue-storefront/core/lib/multistore';
import ArrowRight from '../../svg/ui/ArrowRight';

export default {
  name: 'LinkColumn',
  props: {
    linkList: {
      type: Array,
      required: true
    }
  },
  components: { ArrowRight }
}
</script>
