<template>
  <div class="px-4 py-4" :class="width" v-if="images && images.length">
    <slot />
    <article class="relative z-0">
      <div
        class="flex items-center justify-between absolute inset-0 w-full h-full"
      >
        <CarouselBack @clicked="showPrev" />
        <CarouselFwd @clicked="showNext" />
      </div>
      <ul class="overflow-hidden" style="height: 400px">
        <VueSlickCarousel v-bind="settings" ref="carousel">
          <li class="px-3 relative" v-for="image in images" :key="image.id">
            <img :src="image.sourceUrl" alt="" :style="{ height: '400px' }" />
          </li>
        </VueSlickCarousel>
      </ul>
    </article>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CarouselFwd from './CarouselFwd';
import CarouselBack from './CarouselBack';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import CarouselForProducts from 'theme/components/theme/bikebuilder/external/craftcms/CarouselForProducts';

export default {
  name: 'InstagramCarousel',
  props: {
    blockData: {
      type: Object,
      required: true
    }
  },
  components: {
    CarouselForProducts,
    VueSlickCarousel,
    CarouselBack,
    CarouselFwd
  },
  data() {
    return {
      loaded: false,
      settings: {
        centerMode: true,
        centerPadding: '0px',
        variableWidth: true,
        adaptiveHeight: true,
        focusOnSelect: true,
        slidesToScroll: 1,
        swipeToSlide: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        speed: 500
      }
    };
  },
  computed: {
    ...mapGetters('oculizm', ['allOculizmPosts']),
    imgTransition() {
      return this.loaded ? 'opacity-100' : 'opacity-0';
    },
    images() {
      if (!this.allOculizmPosts || !this.allOculizmPosts.length) return [];
      return this.allOculizmPosts.map((post) => post.featuredImage.node);
    },
    width() {
      if (this.blockData.width === 'standard') {
        return 'mx-auto rc-container max-w-screen-xl';
      }
      if (this.blockData.width === 'wide') {
        return 'mx-auto rc-container max-w-screen-3xl';
      }
      return null;
    }
  },
  methods: {
    showNext() {
      this.$refs.carousel.next();
    },
    showPrev() {
      this.$refs.carousel.prev();
    },
    onImgLoad() {
      this.loaded = true;
    }
  },
  async mounted() {
    const sku = this.blockData ? this.blockData.sku : '';
    await this.$store.dispatch('oculizm/updateOculizmData', sku);
  }
};
</script>
