<template>
  <div class="grid gap-5 px-4 py-4" :class="width">
    <ul class="grid grid-cols-1 gap-5 justify-items-center" :class="gridCalc">
      <li v-for="asset in rowOneAssets" :key="asset.id">
        <AssetGridItem :asset="asset" :blockData="blockData" />
      </li>
    </ul>
    <ul class="grid grid-cols-1 gap-5 justify-items-center" :class="gridCalc">
      <li v-for="asset in rowTwoAssets" :key="asset.id">
        <AssetGridItem :asset="asset" :blockData="blockData" />
      </li>
    </ul>
    <ul class="grid grid-cols-1 gap-5 justify-items-center" :class="gridCalc">
      <li v-for="asset in rowThreeAssets" :key="asset.id">
        <AssetGridItem :asset="asset" :blockData="blockData" />
      </li>
    </ul>
  </div>
</template>

<script>
import AssetGridItem from './AssetGridItem';

export default {
  name: 'AssetGridStacked',
  props: {
    blockData: {
      type: Object,
      required: true
    }
  },
  components: { AssetGridItem },
  computed: {
    rowOneAssets() {
      if (!this.blockData) return [];
      return this.blockData.rowOneAssets;
    },
    rowTwoAssets() {
      if (!this.blockData) return [];
      return this.blockData.rowTwoAssets;
    },
    rowThreeAssets() {
      if (!this.blockData) return [];
      return this.blockData.rowThreeAssets;
    },
    width() {
      if (this.blockData.width === 'standard') {
        return 'mx-auto max-w-screen-xl';
      }
      if (this.blockData.width === 'wide') {
        return 'mx-auto max-w-screen-3xl';
      }
      return null;
    },
    gridCalc() {
      if (!this.blockData) return 'md:grid-cols-1 lg:grid-cols-1';
      const rowOne = this.rowOneAssets.length;
      const rowTwo = this.rowTwoAssets.length;
      const rowThree = this.rowThreeAssets.length;
      const gridCount = Math.max(rowOne, rowTwo, rowThree);
      return gridCount <= 1
        ? 'md:grid-cols-1 lg:grid-cols-1'
        : `sm:grid-cols-2 lg:grid-cols-${gridCount}`;
    }
  }
};
</script>
