<template>
  <div class="mx-auto rc-container max-w-screen-xl">
    <div class="px-4 py-4 cms-content-styled" v-html="blockData.text" />
    <div class="max-w-screen-md mx-auto py-10 relative">
      <form @submit.prevent="subscribe" class="flex items-center">
        <label class="rc" for="rc_name"></label>
        <input type="text" name="rc_name" autocomplete="off" class="rc" />
        <input
          v-model="email"
          autocomplete="email"
          required
          :placeholder="$t('Enter your email here')"
          class="border border-gray-400 appearance-none focus:outline-0 focus:ring-0 focus:outline-none px-2"
        />
        <button
          type="submit"
          class="text-white bg-blue-500 px-5 py-2 border flex items-center justify-center space-x-2 whitespace-nowrap"
        >
          <span class="uppercase font-bold text-lg">{{ $t('Sign Up') }}</span>
          <i class="fa fa-arrow-right" />
        </button>
      </form>
      <Transition name="fade">
        <div v-if="success" class="absolute -bottom-3 left-0 py-5">
          {{ $t('You have been successfully subscribed to our newsletter!') }}
        </div>
      </Transition>
      <Transition name="fade">
        <div v-if="error" class="absolute -bottom-3 left-0 text-red-500 py-5">
          {{ error }}
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import config from 'config';

export default {
  name: 'EmailSignUp',
  props: {
    blockData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      email: '',
      rc_name: null,
      success: false,
      error: ''
    };
  },
  computed: {
    query() {
      return `mutation {
        subscribeEmailToNewsletter(email: "${this.email}") {
          status
        }
      }`;
    }
  },
  methods: {
    async subscribe() {
      this.error = '';
      if (this.rc_name === null) {
        try {
          const res = await axios.post(config.m2GraphqlUrl, {
            query: this.query
          });
          if (res.data.errors && res.data.errors.length) {
            this.error = res.data.errors[0].message;
            this.success = false;
            return;
          }
          if (
            res.data.data.subscribeEmailToNewsletter.status === 'SUBSCRIBED'
          ) {
            this.success = true;
            this.email = '';
            setTimeout(() => {
              this.success = false;
            }, 5000);
          }
        } catch (error) {
          console.error(error);
          this.success = false;
        }
      }
    }
  }
};
</script>

<style scoped>
.rc {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}
</style>
