<template>
  <div :class="width">
    <TextImage :block-data="blockData" :dark-mode="darkMode" />
  </div>
</template>

<script>
import TextImage from 'theme/components/theme/bikebuilder/external/craftcms/TextImage';

export default {
  name: 'TextByImage',
  props: {
    blockData: {
      type: Object,
      required: true
    },
    darkMode: {
      type: String,
      default: 'off'
    }
  },
  components: { TextImage },
  computed: {
    cta() {
      const cta = this.blockData.cta;
      if (!cta || !cta.length || !cta[0].ctaText) return null;
      return this.blockData.cta[0];
    },
    image() {
      if (
        !this.blockData ||
        !this.blockData.image ||
        !this.blockData.image.length
      )
        return null;
      return this.blockData.image[0].url;
    },
    imagePos() {
      return this.blockData.imagePosition === 'right' ? 'md:order-last' : null;
    },
    ctaPosition() {
      if (!this.cta) return null;
      if (this.cta.position === 'left') {
        return 'flex justify-left';
      }
      if (this.cta.position === 'center') {
        return 'flex justify-center';
      }
      return 'flex justify-end';
    },
    ctaStyle() {
      if (!this.cta) return null;
      if (this.cta.style === 'primary' && this.darkMode === 'off') {
        return 'bg-blue-500 text-white hover:bg-blue-400 hover:text-white';
      }
      if (this.cta.style === 'secondary' && this.darkMode === 'off') {
        return 'bg-white text-blue-500 border border-blue-500 hover:text-blue-400';
      }
      if (this.cta.style === 'primary' && this.darkMode === 'on') {
        return 'bg-blue-500 text-white hover:bg-blue-400 hover:text-white';
      }
      if (this.cta.style === 'secondary' && this.darkMode === 'on') {
        return 'text-blue-500 border border-blue-500 hover:text-blue-400';
      }
    },
    insetImage() {
      return this.blockData.insetImage ? 'p-5' : null;
    },
    width() {
      if (this.blockData.width === 'standard') {
        return `${this.insetImage} mx-auto rc-container max-w-screen-xl`;
      }
      if (this.blockData.width === 'wide') {
        return `${this.insetImage} mx-auto rc-container max-w-screen-3xl`;
      }
      return `${this.insetImage}`;
    }
  }
};
</script>
