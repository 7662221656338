<template>
  <div>
    <router-link v-if="asset.ctaUrl" :to="localizedRoute(asset.ctaUrl)" class="block group relative">
      <VideoImage :asset="asset" :height="height" :dimensions="dimensions" />
      <div  v-if="asset.ctaText" class="absolute top-0 left-0 h-full w-full">
        <div class="flex justify-center h-full" :class="positionCta(asset)">
          <span
            class="truncate text-white group-hover:text-blue-500 bg-black bg-opacity-80 px-2 py-2 text-xs xl:px-4 xl:py-2 xl:text-sm">
            {{ asset.ctaText }}
          </span>
        </div>
      </div>
    </router-link>

    <div v-if="isImgWithVideo" class="block group relative">
      <VideoImage :asset="asset" :height="height" :dimensions="dimensions" />
      <div v-if="asset.ctaText" class="absolute top-0 left-0 h-full w-full">
        <div class="flex justify-center h-full" :class="positionCta(asset)">
          <span
            class="truncate text-white group-hover:text-blue-500 bg-black bg-opacity-80 px-2 py-2 text-xs xl:px-4 xl:py-2 xl:text-sm cursor-pointer" @click="openModal">
            {{ asset.ctaText }}
          </span>
        </div>
      </div>
    </div>

    <div v-if="isImg && !asset.ctaUrl">
      <VideoImage :asset="asset" :height="height" :dimensions="dimensions" />
    </div>

    <transition name="fade">
      <VideoModal
        v-if="isImgWithVideo && modalOpen"
        :src="clickedImageUrl"
        :block-data="blockData"
        :poster="imgPoster"
        @close="modalOpen = false"
      />
    </transition>
  </div>
</template>

<script>
import { localizedRoute } from '@vue-storefront/core/lib/multistore';
import VideoImage from './VideoImage';
import VideoModal from './VideoModal';

export default {
  name: 'AssetGridItem',
  props: {
    asset: {
      type: Object,
      required: true
    },
    blockData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      modalOpen: false,
      clickedImageUrl: null
    };
  },
  components: { VideoImage, VideoModal },
  computed: {
    height() {
      if (this.blockData.height === 'standard') {
        return 'rc-img-standard';
      }
      if (this.blockData.height === 'tall') {
        return 'rc-img-tall';
      }
    },
    dimensions() {
      if (this.blockData.imageAspectRatio === 'square') {
        return 'rc-img-square';
      }
      if (this.blockData.imageAspectRatio === 'portrait') {
        return 'rc-img-portrait';
      }
      if (this.blockData.imageAspectRatio === 'landscape') {
        return 'rc-img-landscape';
      }
      return null
    },
    isImgWithCta() {
      return this.asset.ctaText && this.asset.ctaUrl;
    },
    isImgWithVideo() {
      return this.asset.ctaText && this.asset.vimeoUrl;
    },
    isImg() {
      return !this.isImgWithCta && !this.isImgWithVideo;
    },
    imgPoster() {
      if (!this.asset.fallbackImage.length) return null;
      return this.asset.fallbackImage[0].url;
    },
  },
  methods: {
    positionCta(asset) {
      if (asset.ctaPosition === 'top') return 'items-start';
      if (asset.ctaPosition === 'middle') return 'items-center';
      return 'items-end';
    },
    openModal() {
      this.modalOpen = true;
      this.clickedImageUrl = this.asset.vimeoUrl;
    },
  }
};
</script>

<style scoped>
.rc-img-tall,
.rc-img-standard {
  height: 200px;
}

.rc-img-square {
  width: 300px;
  height: 300px;
}

.rc-img-portrait {
  width: 200px;
  height: 275px;
}

.rc-img-landscape {
  width: 320px;
  height: 200px;
}

@media (min-width: 1024px) {
  .rc-img-standard {
    height: 250px;
  }

  .rc-img-tall {
    height: 500px;
  }

  .rc-img-square {
    width: 250px;
    height: 250px;
  }

  .rc-img-portrait {
    width: 250px;
    height: 333px;
  }

  .rc-img-landscape {
    width: 444px;
    height: 250px;
  }
}
</style>
