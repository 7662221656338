<template>
  <div class="" :class="dimensions">
    <video
      v-if="asset.extension === 'm4v'"
      muted
      loop
      autoplay
      playsinline
      loading="lazy"
      :class="dimensions"
      :height="height"
      :poster="asset.fallbackImage.length ? asset.fallbackImage[0].url : null"
      class="object-center object-cover"
    >
      <source :src="asset.url" type="video/mp4" />
    </video>
    <img
      v-else
      :src="asset.url"
      :alt="asset.alt"
      :class="dimensions"
      :height="height"
      class="object-center object-cover"
      loading="lazy"
    />
  </div>
</template>

<script>
export default {
  name: 'VideoImage',
  props: {
    asset: {
      type: Object,
      required: true
    },
    height: {
      type: String,
      default: null
    },
    dimensions: {
      type: String,
      default: null
    }
  }
};
</script>

<style scoped>
.rc-img-tall, .rc-img-standard {
  height: 200px;
}

.rc-img-square {
    width: 300px;
    height: 300px;
  }

  .rc-img-portrait {
    width: 200px;
    height: 275px;
  }

  .rc-img-landscape {
    width: 320px;
    height: 200px;
  }

@media (min-width: 1024px) {
  .rc-img-standard {
    height: 250px;
  }
  .rc-img-tall {
    height: 500px;
  }

  .rc-img-square {
    width: 250px;
    height: 250px;
  }

  .rc-img-portrait {
    width: 250px;
    height: 333px;
  }

.rc-img-landscape {
    width: 444px;
    height: 250px;
  }
}
</style>

