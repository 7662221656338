<template>
  <div class="rc-container w-full mx-auto">
    <dl class="w-full md:px-6">
      <button class="text-left flex justify-between items-center w-full" @click="showDropdown = !showDropdown">
        <dt>
          <p v-if="blockData.body" class=" text-gray-800 m-0">
            {{ blockData.title }}
          </p>
          <p v-if="blockData.menuHeading" class="text-gray-800 m-0">
            {{ blockData.menuHeading }}
          </p>
        </dt>
        <span aria-label="toggler"
          class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 items-center" >
          <DownArrow v-if="!showDropdown"
            class="h-5 text-gray-600" />
          <UpArrow v-if="showDropdown"
            class="h-5 text-gray-600" />
        </span>
      </button>
      <dd class="w-full drop-container" :class="{ open: showDropdown }">
        <p v-if="blockData.body" class=" text-gray-600 m-0 pt-6" v-html="blockData.body" />
        <p v-if="blockData.menuHeading" class="text-gray-600 pt-6">
          {{ blockData.menuText }}
        </p>
      </dd>
    </dl>
  </div>
</template>

<script>
import DownArrow from '../main-footer/DownArrow.vue';
import UpArrow from '../ui-icons/UpArrow.vue';

export default {
  name: "AccordionMenuItem",
  data() {
    return {
      showDropdown: false,
    };
  },
  components: { UpArrow, DownArrow },
  props: {
    blockData: {
      type: Object,
      required: true,
    },
  }
};
</script>

<style scoped>
.drop-container {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease;
}

.drop-container.open {
  max-height: 500px;
}
</style>
