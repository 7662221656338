<template>
  <div class="rc-container mx-auto max-w-screen-xl px-4 py-4" :class="gridCols">
    <section v-if="blockData.linkListOne &&  blockData.linkListOne.length">
      <h3 class="text-xl font-bold my-2 uppercase">
        {{ blockData.headingListOne }}
      </h3>
      <LinkColumn :link-list="blockData.linkListOne" />
    </section>
    <section v-if="blockData.linkListTwo &&  blockData.linkListTwo.length">
      <h3 class="text-xl font-bold my-2 uppercase">
        {{ blockData.headingListOne }}
      </h3>
      <LinkColumn :link-list="blockData.linkListTwo" />
    </section>
    <section v-if="blockData.linkListThree &&  blockData.linkListThree.length">
      <h3 class="text-xl font-bold my-2 uppercase">
        {{ blockData.headingListOne }}
      </h3>
      <LinkColumn :link-list="blockData.linkListThree" />
    </section>
    <section v-if="blockData.linkListFour &&  blockData.linkListFour.length">
      <h3 class="text-xl font-bold my-2 uppercase">
        {{ blockData.headingListOne }}
      </h3>
      <LinkColumn :link-list="blockData.linkListFour" />
    </section>
  </div>
</template>

<script>
import LinkColumn from './LinkColumn';

export default {
  name: 'LinkList',
  props: {
    blockData: {
      type: Object,
      required: true
    }
  },
  components: { LinkColumn },
  computed: {
    gridCols() {
      const gridCols = [];
      const blockData = this.blockData;
      if (blockData.linkListOne && blockData.linkListOne.length) {
        gridCols.push(blockData.linkListOn);
      }
      if (blockData.linkListTwo && blockData.linkListTwo.length) {
        gridCols.push(blockData.linkListTwo);
      }
      if (blockData.linkListThree && blockData.linkListThree.length) {
        gridCols.push(blockData.linkListThree);
      }
      if (blockData.linkListFour && blockData.linkListFour.length) {
        gridCols.push(blockData.linkListFour);
      }
      if (gridCols.length >= 3) {
        return 'flex flex-wrap gap-10 justify-between lg:justify-around';
      }
      return 'flex flex-wrap gap-16 justify-start';
    }
  }
}
</script>
