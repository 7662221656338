<template>
  <transition name="fade">
    <div
      class="fixed w-screen h-screen left-0 top-0 z-50 bg-black bg-opacity-85 flex justify-center items-center transition p-5"
      @click="close">
      <article class="relative" @click.stop>
        <button type="button"
          class="absolute -top-4 -right-4 z-10 text-blue-500 bg-black w-10 h-10 bg-opacity-70 rounded-full flex items-center justify-center"
          @click="close">
          <Cross class="h-4 w-4" />
        </button>

          <video class="object-center max-w-sm sm:max-w-xl md:max-w-3xl xl:max-w-7xl p-5" controls :poster="imgPoster">
            <source :src="src" type="video/mp4">
          </video>
      </article>
    </div>
  </transition>
</template>

<script>
import Cross from 'theme/components/theme/bikebuilder/svg/ui/Cross';

export default {
  name: 'VideoModal',
  props: {
    src: {
      type: String,
      default: null
    },
    blockData: {
      type: Object,
      required: true
    },
    poster: {
      type: String,
      default: null
    }
  },
  computed: {
    imgPoster() {
      if (!this.poster) return null;
      return this.poster;
    }
  },
  components: {
    Cross
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}


.rc-modal {
  width: 98vw;
}

@media (min-width: 768px) {
  .rc-modal {
    width: 800px;
  }
}
</style>
