<template>
  <div>
    <div>
      <article v-if="images && images.length" class="relative z-0">
        <div
          class="flex items-center justify-between absolute inset-0 w-full h-full"
        >
          <CarouselBack @clicked="showPrev" :btnBg="btnBg" />
          <CarouselFwd @clicked="showNext" :btnBg="btnBg" />
        </div>

        <VueSlickCarousel v-bind="settings" ref="carousel">
          <button
            v-for="(image, index) in images"
            :key="image.id"
            type="button"
            @click="imageClick(image.url, index)"
            class="block px-2"
          >
            <img :src="image.url" alt="" :style="{'max-height': imgHeight}" class="object-cover" />
          </button>
        </VueSlickCarousel>
      </article>
    </div>
    <transition name="fade">
      <ImageCarouselModal
        v-if="modalOpen"
        :block-data="blockData"
        :clicked-image-index="clickedImageIndex"
        :clicked-image-url="clickedImageUrl"
        :is-craft="false"
        @close="modalOpen = false"
      />
    </transition>
  </div>
</template>

<script>
import CarouselFwd from './CarouselFwd';
import CarouselBack from './CarouselBack';
import VueSlickCarousel from 'vue-slick-carousel';
import ImageCarouselModal from './ImageCarouselModal';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';

export default {
  name: 'CarouselForProducts',
  props: {
    blockData: {
      type: Object,
      required: true
    },
    slidesToShow: {
      type: Number,
      default: 4
    },
    imgHeight: {
      type: String,
      default: '250px'
    },
    btnBg: {
      type: Boolean,
      default: true
    }
  },
  components: { VueSlickCarousel, CarouselBack, CarouselFwd, ImageCarouselModal },
  data () {
    return {
      clickedImageUrl: null,
      clickedImageIndex: null,
      modalOpen: false,
      settings: {
        centerMode: true,
        centerPadding: "0px",
        variableWidth: true,
        adaptiveHeight: true,
        focusOnSelect: true,
        slidesToScroll: 1,
        swipeToSlide: true,
        arrows: false,
        infinite: true,
        slidesToShow: this.slidesToShow,
        speed: 500,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      }
    };
  },
  computed: {
    images () {
      if (!this.blockData) return [];
      return this.blockData.images;
    }
  },
  methods: {
    showNext () {
      this.$refs.carousel.next();
    },
    showPrev () {
      this.$refs.carousel.prev();
    },
    imageClick (imageUrl, index) {
      this.modalOpen = true;
      this.clickedImageUrl = imageUrl;
      this.clickedImageIndex = index;
    }
  }
};
</script>
