<template>
  <div class="rc-container mx-auto px-5 py-10 max-w-7xl" :style="`background-color: ${bgColour}`">
    <h3 class="font-featured text-2xl md:text-4xl">
      <div class="font-bold">
        {{ $t('Latest') }}
      </div>
      <div>{{ $t('Articles') }}</div>
    </h3>
    <PostCarousel />
  </div>
</template>

<script>
import PostCarousel from 'theme/components/theme/bikebuilder/external/craftblog/PostCarousel';

export default {
  name: 'CarouselForBlog',
  props: {
    blockData: {
      type: Object,
      required: true
    }
  },
  components: { PostCarousel },
  computed: {
    bgColour() {
      return this.blockData ? this.blockData.backgroundColour : '#fffffff';
    }
  }
}
</script>
