<template>
  <div class="flex flex-col gap-6 md:gap-0" :class="imagePos">
    <div class="mx-auto" :class="imageWidth">
      <router-link v-if="cta && cta.ctaUrl" :to="localizedRoute(cta.ctaUrl)">
        <img :src="image" alt="" class="object-cover" />
      </router-link>
      <img v-else :src="image" alt="" class="object-cover" />
    </div>
    <div :class="textWidth" class="p-5 grid grid-cols-1">
      <div>
        <h2 v-if="blockData.heading" class="m-0 text-2xl font-bold">
          {{ blockData.heading }}
        </h2>
        <div
          v-if="blockData.text"
          v-html="blockData.text"
          class="cms-content-styled"
        />
        <div v-if="cta && cta.ctaUrl" :class="ctaPosition" class="mt-4">
          <button
            :to="localizedRoute(cta.ctaUrl)"
            type="button"
            @click="$router.push(localizedRoute(cta.ctaUrl)).catch((err) => {})"
            class="px-5 py-3 transition uppercase font-bold inline-flex items-center space-x-2 whitespace-nowrap"
            :class="ctaStyle"
          >
            <span>{{ cta.ctaText }}</span>
            <ArrowRight class="h-4" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { localizedRoute } from '@vue-storefront/core/lib/multistore';
import ArrowRight from 'theme/components/theme/bikebuilder/svg/ui/ArrowRight';

export default {
  name: 'TextImage',
  props: {
    blockData: {
      type: Object,
      required: true
    },
    darkMode: {
      type: String,
      default: 'off'
    }
  },
  components: { ArrowRight },
  computed: {
    cta() {
      const cta = this.blockData.cta;
      if (!cta || !cta.length || !cta[0].ctaText) return null;
      return this.blockData.cta[0];
    },
    image() {
      if (
        !this.blockData ||
        !this.blockData.image ||
        !this.blockData.image.length
      )
        return null;
      return this.blockData.image[0].url;
    },
    textPos() {
      if (!this.blockData.textPosition) return 'content-start';
      switch (this.blockData.textPosition) {
        case 'middle':
          return 'content-center';
        case 'bottom':
          return 'content-end';
        default:
          return 'content-start';
      }
    },
    imagePos() {
      if (!this.blockData.imagePosition) return 'sm:flex-row';
      return this.blockData.imagePosition === 'right'
        ? 'sm:flex-row-reverse'
        : 'sm:flex-row';
    },
    imageWidth() {
      if (!this.blockData.imageWidth) return 'sm:w-1/3';
      return this.blockData.imageWidth === 'third'
        ? `${this.imageWidthMobile} sm:w-1/3`
        : `${this.imageWidthMobile} sm:w-1/2`;
    },
    imageWidthMobile() {
      if (!this.blockData.imageWidthMobile) return 'w-full';
      switch (this.blockData.imageWidthMobile) {
        case 'third':
          return 'w-1/3';
        case 'half':
          return 'w-1/2';
        default:
          return 'w-full';
      }
    },
    textWidth() {
      if (!this.blockData.imageWidth) return 'sm:w-3/4';
      return this.blockData.imageWidth === 'third'
        ? `${this.textPos} sm:w-3/4`
        : `${this.textPos} sm:w-1/2`;
    },
    ctaPosition() {
      if (!this.cta) return null;
      if (this.cta.position === 'left') {
        return 'flex justify-left';
      }
      if (this.cta.position === 'center') {
        return 'flex justify-center';
      }
      return 'flex justify-end';
    },
    ctaStyle() {
      if (!this.cta) return null;
      if (this.cta.style === 'primary' && this.darkMode === 'off') {
        return 'bg-blue-500 text-white hover:bg-blue-400 hover:text-white';
      }
      if (this.cta.style === 'secondary' && this.darkMode === 'off') {
        return 'bg-white text-blue-500 border border-blue-500 hover:text-blue-400';
      }
      if (this.cta.style === 'primary' && this.darkMode === 'on') {
        return 'bg-blue-500 text-white hover:bg-blue-400 hover:text-white';
      }
      if (this.cta.style === 'secondary' && this.darkMode === 'on') {
        return 'text-blue-500 border border-blue-500 hover:text-blue-400';
      }
    }
  }
};
</script>
