<template>
  <div class="overflow-y-hidden">
    <div class="px-4 py-4 mx-auto rc-container max-w-screen-xl">
      <h1 class="text-2xl text-gray-800 font-semibold md:px-6 border-b m-0 py-6 lg:text-3xl">
        {{ blockData.heading }}</h1>
        <div v-if="questions">
          <AccordionMenuItem v-for="(question, key) in questions" :key="key" :blockData="question" class="border-b py-6"/>
        </div>
        <div v-if="menuItems">
          <AccordionMenuItem v-for="(menu, key) in menuItems" :key="key" :blockData="menu" class="border-b py-6"/>
        </div>
    </div>
  </div>
</template>

<script>
import AccordionMenuItem from "./AccordionMenuItem";

export default {
  name: "AccordionMenu",
  components: { AccordionMenuItem },
  props: {
    blockData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    questions() {
      if(!this.blockData.faqs) return null;
      return this.blockData.faqs;
    },
    menuItems() {
      if(!this.blockData.menuItems) return null;
      return this.blockData.menuItems;
    },
  }
};
</script>
