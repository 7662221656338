<template>
  <li :style="containerStyles">
    <Hero v-if="block.typeHandle === 'hero'" :block-data="block" />

    <Carousel
      v-if="block.typeHandle === 'carousel'"
      :block-data="block"
      :is-craft="true"
    />

    <InstagramCarousel
      v-if="block.typeHandle === 'instagramCarousel'"
      :block-data="block"
    />

    <CtaBlock
      v-if="block.typeHandle === 'cta'"
      :block-data="block"
      :darkMode="darkMode"
    />

    <Quote v-if="block.typeHandle === 'quote'" :block-data="block" />

    <ImageBlock v-if="block.typeHandle === 'image'" :block-data="block" />

    <VideoBlock v-if="block.typeHandle === 'video'" :block-data="block" />

    <HeadingBlock
      v-if="block.typeHandle === 'headingBlock'"
      :block-data="block"
    />

    <HeadingDouble
      v-if="block.typeHandle === 'headingDouble'"
      :block-data="block"
    />

    <TextBlock v-if="block.typeHandle === 'textBlock'" :block-data="block" />

    <MenuBlock v-if="block.typeHandle === 'menuBlock'" :block-data="block" />

    <TextByImage
      v-if="block.typeHandle === 'textByImage'"
      :block-data="block"
      :dark-mode="darkMode"
    />

    <TextByVideo
      v-if="block.typeHandle === 'textByVideo'"
      :block-data="block"
      :dark-mode="darkMode"
    />

    <ImageGrid v-if="block.typeHandle === 'imageGrid'" :block-data="block" />

    <ImageTextGrid
      v-if="block.typeHandle === 'imageTextGrid'"
      :block-data="block"
      :dark-mode="darkMode"
    />

    <AssetGridStacked
      v-if="block.typeHandle === 'assetGridStacked'"
      :block-data="block"
    />

    <TextOnImage
      v-if="block.typeHandle === 'textOnImage'"
      :block-data="block"
    />

    <LinkListBlock
      v-if="(block.typeHandle === 'linkList') === true"
      :block-data="block"
    />

    <DividerBlock
      v-if="block.typeHandle === 'divider' && block.divider === true"
      :block-data="block"
    />

    <AccordionMenu
      v-if="
        block.typeHandle === 'faqMenu' || block.typeHandle === 'accordionMenu'
      "
      :block-data="block"
    />

    <div
      v-if="block.typeHandle === 'spacer' && block.spacer === true"
      class="py-4 md:py-8"
    ></div>

    <TrustPilot v-if="block.typeHandle === 'trustPilot'" />

    <CarouselForBlog
      v-if="block.typeHandle === 'blogCarousel'"
      :block-data="block"
    />

    <EmailSignUp
      v-if="block.typeHandle === 'emailSignUp'"
      :block-data="block"
    />
  </li>
</template>

<script>
import Hero from 'theme/components/theme/bikebuilder/external/craftcms/Hero';
import Quote from 'theme/components/theme/bikebuilder/external/craftcms/Quote';
import CtaBlock from 'theme/components/theme/bikebuilder/external/craftcms/CtaBlock';
import Carousel from 'theme/components/theme/bikebuilder/external/craftcms/Carousel';
import ImageGrid from 'theme/components/theme/bikebuilder/external/craftcms/ImageGrid';
import TextBlock from 'theme/components/theme/bikebuilder/external/craftcms/TextBlock';
import MenuBlock from 'theme/components/theme/bikebuilder/external/craftcms/MenuBlock';
import ImageBlock from 'theme/components/theme/bikebuilder/external/craftcms/ImageBlock';
import VideoBlock from 'theme/components/theme/bikebuilder/external/craftcms/VideoBlock';
import TextOnImage from 'theme/components/theme/bikebuilder/external/craftcms/TextOnImage';
import HeadingBlock from 'theme/components/theme/bikebuilder/external/craftcms/HeadingBlock';
import TextByImage from 'theme/components/theme/bikebuilder/external/craftcms/TextByImage';
import TextByVideo from 'theme/components/theme/bikebuilder/external/craftcms/TextByVideo';
import DividerBlock from 'theme/components/theme/bikebuilder/external/craftcms/DividerBlock';
import HeadingDouble from 'theme/components/theme/bikebuilder/external/craftcms/HeadingDouble';
import LinkListBlock from 'theme/components/theme/bikebuilder/external/craftcms/LinkListBlock';
import AccordionMenu from 'theme/components/theme/bikebuilder/external/craftcms/AccordionMenu';
import AssetGridStacked from 'theme/components/theme/bikebuilder/external/craftcms/AssetGridStacked';
import InstagramCarousel from 'theme/components/theme/bikebuilder/external/craftcms/InstagramCarousel';
import CarouselForBlog from 'theme/components/theme/bikebuilder/external/craftcms/CarouselForBlog';
import TrustPilot from 'theme/components/theme/bikebuilder/external/reviews/TrustPilot';
import ImageTextGrid from 'theme/components/theme/bikebuilder/external/craftcms/ImageTextGrid';
import EmailSignUp from 'theme/components/theme/bikebuilder/external/craftcms/EmailSignUp';

export default {
  name: 'PageBuilderBlocks',
  props: {
    block: {
      type: Object,
      required: true
    },
    darkMode: {
      type: String,
      default: 'off'
    }
  },
  components: {
    Hero,
    Quote,
    CtaBlock,
    Carousel,
    ImageGrid,
    MenuBlock,
    TextBlock,
    TrustPilot,
    ImageBlock,
    VideoBlock,
    TextByVideo,
    TextOnImage,
    HeadingBlock,
    TextByImage,
    DividerBlock,
    HeadingDouble,
    LinkListBlock,
    AccordionMenu,
    AssetGridStacked,
    InstagramCarousel,
    CarouselForBlog,
    ImageTextGrid,
    EmailSignUp
  },
  computed: {
    textColour() {
      return this.block.textColour ? this.block.textColour : null;
    },
    bgColour() {
      return this.block.backgroundColour ? this.block.backgroundColour : null;
    },
    containerStyles() {
      if (this.block.typeHandle === 'menuBlock') return null;
      return `color: ${this.textColour}; background-color: ${this.bgColour}`;
    }
  }
};
</script>
