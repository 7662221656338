<template>
  <div class="px-4 py-4 flex" :class="[width, position]">
    <a
      v-if="externalUrl"
      :href="blockData.ctaUrl"
      class="px-5 py-3 transition uppercase font-bold inline-flex items-center space-x-2 whitespace-nowrap"
      :class="style"
      target="_blank"
    >
      <span>
        {{ blockData.ctaText }}
      </span>
      <ArrowRight class="h-4" />
    </a>
    <button
      v-else
      type="button"
      @click="$router.push(localizedRoute(blockData.ctaUrl)).catch((err) => {})"
      class="px-5 py-3 transition uppercase font-bold inline-flex items-center space-x-2 whitespace-nowrap"
      :class="style"
    >
      <span>
        {{ blockData.ctaText }}
      </span>
      <ArrowRight class="h-4" />
    </button>
  </div>
</template>

<script>
import { localizedRoute } from '@vue-storefront/core/lib/multistore';
import ArrowRight from '../../svg/ui/ArrowRight.vue';

export default {
  name: 'CtaBlock',
  props: {
    blockData: {
      type: Object,
      required: true
    },
    darkMode: {
      type: String,
      default: 'off'
    }
  },
  components: { ArrowRight },
  computed: {
    width() {
      if (this.blockData.width === 'standard') {
        return 'mx-auto rc-container max-w-screen-xl';
      }
      if (this.blockData.width === 'wide') {
        return 'mx-auto rc-container max-w-screen-3xl';
      }
      return null;
    },
    position() {
      if (this.blockData.position === 'left') return 'justify-left';
      if (this.blockData.position === 'center') return 'justify-center';
      return 'justify-end';
    },
    style() {
      if (this.blockData.style === 'primary') {
        return 'text-white bg-blue-500 hover:bg-blue-400 hover:text-white';
      }
      return this.darkMode === 'on'
        ? 'border text-white'
        : 'bg-black text-white hover:bg-gray-800 hover:text-white';
    },
    externalUrl() {
      if (!this.blockData) return false;
      return this.blockData.externalUrl;
    }
  }
};
</script>

<style scoped>
a:hover,
a:focus {
  color: white !important;
}
a:hover span,
a:focus span {
  color: white !important;
}
</style>
