<template>
  <div
    class="p-5 lg:py-20 lg:pr-20 lg:w-7/12 relative lg:flex lg:justify-end"
    :style="`background-color: ${bgColour}; color: ${textColour};`"
  >
    <div
      class="hidden rc-menu absolute top-0 right-0 transform rotate-45 md:block"
    ></div>
    <div class="grid grid-cols-1 gap-10 sm:grid-cols-2">
      <article v-for="section in blockData.menuSection" :key="section.id">
        <h3 class="uppercase m-0 mb-2 text-2xl font-featured font-bold">
          {{ section.sectionHeading }}
        </h3>
        <ul>
          <li v-for="link in section.sectionLinks" :key="link.linkUrl">
            <router-link
              :to="localizedRoute(`${link.linkUrl}`)"
              class="font-featured text-sm"
            >
              {{ link.linkText }}
            </router-link>
          </li>
        </ul>
      </article>
    </div>
  </div>
</template>

<script>
import { localizedRoute } from '@vue-storefront/core/lib/multistore';

export default {
  name: 'MenuBlock',
  props: {
    blockData: {
      type: Object,
      required: true
    }
  },
  computed: {
    bgColour() {
      return this.blockData ? this.blockData.backgroundColour : '#000000';
    },
    textColour() {
      return this.blockData ? this.blockData.textColour : '#ffffff';
    }
  }
};
</script>

<style scoped>
.rc-menu {
  content: '';
  width: 0;
  height: 0;
  top: -50px;
  right: -50px;
  border-top: 0;
  border: 50px solid transparent;
  border-bottom: 50px solid white;
}
</style>
