<template>
  <div class="px-4 py-4" :class="width">
    <div class="grid md:grid-cols-2 grid-cols-1 gap-6 justify-center p-3">
      <div class="w-full h-full" :class="videoPos">
        <video v-if="video" controls playsinline>
          <source :src="video" type="video/mp4" />
        </video>
      </div>
      <div class="p-3">
        <h2 v-if="blockData.heading" class="m-0 text-2xl font-bold">
          {{ blockData.heading }}
        </h2>
        <div
          v-if="blockData.text"
          v-html="blockData.text"
          class="cms-content-styled"
        />
        <div v-if="cta && cta.ctaUrl" :class="ctaPosition" class="mt-4">
          <button
            :to="localizedRoute(cta.ctaUrl)"
            type="button"
            @click="$router.push(localizedRoute(cta.ctaUrl)).catch((err) => {})"
            class="px-5 py-3 transition uppercase font-bold inline-flex items-center space-x-2 whitespace-nowrap"
            :class="ctaStyle"
          >
            <span>{{ cta.ctaText }}</span>
            <ArrowRight class="h-4" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowRight from '../../svg/ui/ArrowRight';
import { localizedRoute } from '@vue-storefront/core/lib/multistore';

export default {
  name: 'TextByVideo',
  props: {
    blockData: {
      type: Object,
      required: true
    },
    darkMode: {
      type: String,
      default: 'off'
    }
  },
  components: { ArrowRight },
  computed: {
    cta() {
      const cta = this.blockData.cta;
      if (!cta || !cta.length || !cta[0].ctaText) return null;
      return this.blockData.cta[0];
    },
    video() {
      if (!this.blockData || !this.blockData.vimeoUrl) return null;
      return this.blockData.vimeoUrl;
    },
    videoPos() {
      return this.blockData.videoPosition === 'right' ? 'md:order-last' : null;
    },
    ctaPosition() {
      if (!this.cta) return null;
      if (this.cta.position === 'left') {
        return 'flex justify-left';
      }
      if (this.cta.position === 'center') {
        return 'flex justify-center';
      }
      return 'flex justify-end';
    },
    ctaStyle() {
      if (!this.cta) return null;
      if (this.cta.style === 'primary' && this.darkMode === 'off') {
        return 'bg-blue-500 text-white hover:bg-blue-400 hover:text-white';
      }
      if (this.cta.style === 'secondary' && this.darkMode === 'off') {
        return 'bg-white text-blue-500 border border-blue-500 hover:text-blue-400';
      }
      if (this.cta.style === 'primary' && this.darkMode === 'on') {
        return 'bg-blue-500 text-white hover:bg-blue-400 hover:text-white';
      }
      if (this.cta.style === 'secondary' && this.darkMode === 'on') {
        return 'text-blue-500 border border-blue-500 hover:text-blue-400';
      }
    },
    width() {
      if (this.blockData.width === 'standard') {
        return 'mx-auto rc-container max-w-screen-xl';
      }
      if (this.blockData.width === 'wide') {
        return 'mx-auto rc-container max-w-screen-3xl';
      }
      return null;
    }
  }
};
</script>
