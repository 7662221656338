<template>
  <div class="rc.container">
    <client-only>
      <carousel
        class="items"
        :per-page-custom="[[300, 1], [767, 1], [1024, 2]]"
        :controls="true"
        :pagination-enabled="true"
        :navigation-enabled="false"
      >
        <slide v-for="post in blogPosts" :key="post.id">
          <post-item-horizontal :post="post" />
        </slide>
      </carousel>
    </client-only>
  </div>
</template>

<script>
import { mapGetters}  from 'vuex';
import ClientOnly from 'vue-client-only';
import PostItemHorizontal from './PostItemHorizontal';

const co = typeof window !== 'undefined';

export default {
  name: 'PostCarousel',
  components: {
    Carousel: () => co ? import('vue-carousel').then(Slider => Slider.Carousel).catch() : null,
    Slide: () => co ? import('vue-carousel').then(Slider => Slider.Slide).catch() : null,
    PostItemHorizontal,
    ClientOnly
  },
  computed: {
    ...mapGetters({
      posts: 'craftCms/getBlogPostsListing'
    }),
    blogPosts() {
      return this.posts && this.posts.blogEntries ? this.posts.blogEntries.slice(0, 5) : [];
    }
  }
}
</script>

<style lang="scss" scoped>
  // Animations.
  .slide-right {
    -webkit-animation: slide-right 1.2s cubic-bezier(0.600, -0.280, 0.735, 0.045) infinite alternate both;
    animation: slide-right 1.2s cubic-bezier(0.600, -0.280, 0.735, 0.045) infinite alternate both;
    display: inline-block;
    margin-left: 2px;
  }

  @-webkit-keyframes slide-right {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(4px);
      transform: translateX(4px);
    }
  }
  @keyframes slide-right {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(4px);
      transform: translateX(4px);
    }
  }
</style>
