<template>
  <div class="px-4 py-4" :class="width">
    <h3
      v-if="blockData.heading"
      class="font-bold uppercase leading-none m-0"
      :class="style"
    >
      {{ blockData.heading }}
    </h3>
    <h3
      v-if="blockData.headingTwo"
      class="font-light uppercase leading-none m-0"
      :class="style"
    >
      {{ blockData.headingTwo }}
    </h3>
  </div>
</template>

<script>
export default {
  name: 'HeadingDouble',
  props: {
    blockData: {
      type: Object,
      required: true
    }
  },
  computed: {
    width() {
      if (this.blockData.width === 'standard') {
        return 'mx-auto rc-container max-w-screen-xl';
      }
      if (this.blockData.width === 'wide') {
        return 'mx-auto rc-container max-w-screen-3xl';
      }
      return null;
    },
    style() {
      if (this.blockData.style === 'largeLeft') {
        return 'text-left md:text-4xl';
      }
      if (this.blockData.style === 'mediumCenter') {
        return 'text-center md:text-2xl';
      }
      if (this.blockData.style === 'largeCenter') {
        return 'text-center md:text-4xl';
      }
      return 'text-left text-2xl';
    }
  }
};
</script>
